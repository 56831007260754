import "./homepage.css";
import Header from "../components/haeder/header.js";
import FluidAnimation from "../components/webglsimulation/webgl.js";
import Banner from "../components/banner/banner.js";
import Card from "../components/card/card.js";
import {
  WHITE_COLOR,
  BLACK_COLOR,
  PRIMARY_COLOR,
} from "../constants/colors.js";
import YourComponent from "../components/tradetools/trade.js";
import VideoPlayer from "../components/videoPlayer/videoplayer.js";
import TestimonialCarousel from "../components/testimonials/testimonials.js";
import { useState } from "react";
import ModalDialog from "../components/modal/modal.js";
// import ModalCareerDialog from "../components/modal/modalCareer.js";
import Footer from "../components/footer/footer.js";
import SignupForm from "../components/signUp/signup.js";

function Home() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleTalkButton = (event) => {
    event.preventDefault();
    console.log("Let's talk clicked!");
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event:'LetsTalkClick',
    });
    handleOpen();
  };
  // const [openCareer, setCareerOpen] = useState(false);
  // const handleCareerOpen = () => {
  //   setCareerOpen(true);
  // };
  // const handleCareerClose = () => {
  //   setCareerOpen(false);
  // };

  return (
    <div className="App" style={{ "--black": BLACK_COLOR }}>
      <ModalDialog
        className="modal-style"
        open={open}
        handleClose={handleClose}
      />
      {/* <ModalCareerDialog
        className="modal-style"
        open={openCareer}
        handleClose={handleCareerClose}
      /> */}
      <Header handleTalkButton={handleTalkButton} />
      <FluidAnimation />
      <Banner className="items-center" />

      <div className="expertise-container" id="expertise-container">
        <h1 className="h2 expertise-text" style={{ "--white": WHITE_COLOR }}>
          {/* <span className="small-expertise">Our</span> <br />{" "} */}
          <span className="big-experties disable-select">
            We are <span className="brand-color">pros</span> at!
          </span>
        </h1>
        <div className="line-expertise"></div>
        <div className="container-container">
          <div className="container">
            <Card
              image={require("../assets/experties/1.svg").default}
              className="div1"
              title={<>Augmented Reality Development</>}
              description={
                <div className="expertise-content">
                  <span>
                    {" "}
                    &#x2022; Social Camera Effects, Filters, and Lenses for
                    Facebook, Instagram, TikTok, and Snapchat
                  </span>
                  <span> &#x2022; Web AR</span>
                  <span> &#x2022; 2D/ 3D AR Games</span>
                  <span> &#x2022; Mobile AR Apps</span>
                  <span>
                    {" "}
                    &#x2022; AR for Print – Murals, Posters and Packaging (OOH)
                  </span>
                </div>
              }
            />
            <Card
              image={require("../assets/experties/2.svg").default}
              className="div2"
              title={
                <>
                  Virtual Reality <br />
                  Solutions
                </>
              }
              description={
                <div className="expertise-content">
                  <span>
                    {" "}
                    &#x2022;VR Content Creation – Live Action 360 Video
                    Production Services
                  </span>
                  <span> &#x2022; VR Content Creation – CGI and VFX</span>
                  <span> &#x2022; VR Game Development</span>
                  <span> &#x2022; VR Training Simulations</span>
                  <span> &#x2022; VR Hyperrealistic walkthrough</span>
                </div>
              }
            />
            <Card
              image={require("../assets/experties/3.png")}
              className="div3"
              title={<>Custom Application Development</>}
              description={
                <div className="expertise-content">
                  <span>
                    {" "}
                    &#x2022; Apps – Mobile, Desktop and Touchscreen Development
                  </span>
                  <span> &#x2022; Mobile App Development</span>
                  <span>
                    {" "}
                    &#x2022; Development for Next-Generation Technologies – XR
                    (Extended Reality), ML (Machine Learning), AI (Artificial
                    intelligence)
                  </span>
                </div>
              }
            />
            <Card
              image={require("../assets/experties/4.png")}
              className="div4"
              title={
                <>
                  Web <br /> Development
                </>
              }
              description={
                <div className="expertise-content">
                  <span> &#x2022; Web Site, HTML5, and WebGL Development</span>
                  <span>
                    &#x2022; Digital Sweepstake experiences, Loyalty Games,
                    2D/3D Web Games
                  </span>
                  <span>
                    &#x2022; Video and Camera App Development – Digital PhotoOps
                    & Generative AI web experiences
                  </span>
                </div>
              }
            />
            <Card
              image={require("../assets/experties/5.webp")}
              className="div5"
              title={
                <>
                  2D/3D <br />
                  Interactive
                </>
              }
              description={
                <div className="expertise-content">
                  <span> &#x2022; 3D Modelling</span>
                  <span> &#x2022; Product Configurator</span>
                  <span className="overflow-hidden">
                    {" "}
                    &#x2022; 3D Character Modelling
                  </span>
                  <span> &#x2022; 2D/3D Animation</span>
                  <span> &#x2022; Virtual Catalogue</span>
                  <span>
                    {" "}
                    &#x2022; Metaverse
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </div>
              }
            />
            <Card
              image={require("../assets/experties/6.webp")}
              className="div6"
              title={<>Interactive Video Experiences</>}
              description={
                <div className="expertise-content">
                  <span> &#x2022; CGI & VFX</span>
                  <span> &#x2022; Fake Out Of Home Video Ads (FOOH)</span>
                  <span> &#x2022; Hyperrealistic 3D Renderings</span>
                  <span>
                    &#x2022; Editorial and Post-Production Video Services
                  </span>
                </div>
              }
            />
          </div>
        </div>
      </div>

      {/* <div style={{ marginTop: "15%" }} className="tools"> */}
      <YourComponent />
      {/* </div> */}

      <div className="videos" id="videos">
        <VideoPlayer />
      </div>

      {/* <div className="testimonials">
        <TestimonialCarousel />
      </div> */}

      <div className="signup-parent-container" style={{}}>
        <SignupForm />
      </div>

      <div className="lets-container">
        <div className="letstalk-info">
          <div className="letstalk-text disable-select">
            Want to <span className="brand-color">discuss</span> an idea,
            project, or campaign?
          </div>
          <div className="letstalk-button">
            <div onClick={handleTalkButton} class="button-main" id="button-6">
              <div id="spin"></div>
              <a id="head-cta-get-in-touch" href="/contact">
                Let's talk!
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-contaienr">
        <Footer handleTalkButton={handleTalkButton} />
      </div>
    </div>
  );
}

export default Home;

// name, email, phone number, resume, message box
