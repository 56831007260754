import React from "react";
import Dialog from "@mui/material/Dialog";
import MyForm from "./form";
const ModalDialog = ({ open, handleClose }) => {
  return (
    // props received from App.js
    <Dialog fullWidth={true} open={open} onClose={handleClose}>
      <MyForm handleClose={handleClose} />
    </Dialog>
  );
};

export default ModalDialog;
