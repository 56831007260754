import "./styles.css";
import { useRef, useEffect, useState } from "react";
// import WebGLFluid from "webgl-fluid";
// import "./script.js";
import { WHITE_COLOR } from "../../constants/colors";
import { MAIN_FONT } from "../../constants/fonts";

export default function FluidAnimation() {
  const canvasref = useRef(null);
  useEffect(() => {
    const existingScript = document.querySelector('script[src="./script.js"]');
    
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "./script.js"; // Path to your script.js file
      
      document.body.appendChild(script);
  
      script.onload = () => {
        // Initialize or call functions from your script here if needed
      };
  
      return () => {
        // Clean up logic if needed, e.g., removing event listeners or resetting variables
        document.body.removeChild(script);
      };
    }
  
  }, []);
  


  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "40%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <text
        id="heading"
        style={{
          position: "absolute",
          top: "20%",
          zIndex: 99,
          color: WHITE_COLOR,
          textAlign: "center",
          alignSelf: "flex-start",
          width: "100%",
          fontFamily: `${MAIN_FONT}`,
          fontSize: "35px",
          pointerEvents: "none"
        }}
      >
        We Provide Cutting-Edge Solutions And Immersive Experiences
      </text>
      <img
          id="logo3d"
          src={require("../../assets/logo.webp")}
          loading="lazy"
          alt="Logo"
          className="navbar-logo-image height-100"
          style={{
            position: "absolute",
            textAlign: "center",
            pointerEvents: "none"
          }}
        />
      <canvas
        ref={canvasref}
        style={{ top: "0", left: "0", width: "100%", height: "100vh" }}
      ></canvas>
    </div>
  );
}



