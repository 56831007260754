import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import emailjs from "@emailjs/browser";
import "./modal.css"; // Adjust the path as necessary

const MyForm = ({ handleClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contactNumber: "",
    email: "",
    purpose: "",
    attachment: "",
    message: "",
  });
  const [fileName, setFileName] = useState("");
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      setFileName(file.name);
      setFormData((prev) => ({ ...prev, attachment: file }));
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataWithAttachment = {
      ...formData,
      attachment: fileName,
    };

    emailjs
      .send(
        "service_l1aub4b",
        "template_yk87khi",
        formDataWithAttachment,
        "Bb4aHsqZx7UBG0kIa"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setAlert(
            <Alert severity="success" onClose={handleClose}>
              <AlertTitle>Success</AlertTitle>
              Email successfully sent!
            </Alert>
          );
          setLoading(false);
          setTimeout(() => handleClose(), 2000); // Automatically close modal after 3 seconds
        },
        (error) => {
          console.log("Failed to send the email.", error.text);
          setAlert(
            <Alert severity="error" onClose={() => setAlert(null)}>
              <AlertTitle>Error</AlertTitle>
              Failed to send the email. Please try again.
            </Alert>
          );
          setLoading(false);
        }
      );
  };

  return (
    <div className="form-container">
      <div className="item-5container">
        <button className="item-5-form" onClick={handleClose}></button>
      </div>
      <form
        className="jotform-form"
        onSubmit={sendEmail}
        method="post"
        name="form_241294427210449"
        id="241294427210449"
        acceptCharset="utf-8"
        autoComplete="on"
      >
        {alert && <div className="alert-container">{alert}</div>}
        <div className="two-element">
          <div className="width50">
            <label htmlFor="first_3">First Name</label>
            <input
              type="text"
              id="first_3"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              disabled={loading}
            />
          </div>
          <div className="width50">
            <label htmlFor="last_3">Last Name</label>
            <input
              type="text"
              id="last_3"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              disabled={loading}
            />
          </div>
        </div>
        <div className="two-element">
          <div className="width50">
            <label htmlFor="input_5_phone">Phone Number</label>
            <input
              type="tel"
              id="input_5_phone"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              disabled={loading}
            />
          </div>
          <div className="width50">
            <label htmlFor="input_225">E-mail</label>
            <input
              type="email"
              id="input_225"
              name="email"
              value={formData.email}
              onChange={handleChange}
              disabled={loading}
            />
          </div>
        </div>
        <div>
          <label htmlFor="input_246">Attachment</label>
          <label htmlFor="input_246" className="custom-file-upload">
            {fileName ? fileName : "Choose file"}
          </label>
          <input
            type="file"
            id="input_246"
            name="attachment"
            accept=".pdf,.docx"
            style={{ display: "none" }}
            onChange={handleFileChange}
            disabled={loading}
          />
        </div>
        <div>
          <label htmlFor="input_247">Message</label>
          <textarea
            id="input_247"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="8"
            disabled={loading}
          ></textarea>
        </div>
        <button type="submit" className="submitletstalk" disabled={loading}>
          Submit
          {loading && <div className="loading-spinner"></div>}
        </button>
      </form>
    </div>
  );
};

export default MyForm;
