import React, { useEffect, useState } from "react";
import { useInView } from 'react-intersection-observer';
import "./card.css"; 

function Card({ image, title, description, className }) {

  const { ref, inView } = useInView({
    triggerOnce: false, // Allow the animation to trigger every time the card comes into view
    threshold: 1, // Adjust threshold as needed
  });

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`card ${className} ${inView && isSmallScreen ? "animate" : ""}`}  ref={ref} >
      <img src={image} alt="card" className="disable-select" />
      <div className="hover-content-container-f disable-select">
        <div className="title-container-f">
          <h3 className="hover-title-f">{title}</h3>
          <div className="horizontal-bar"></div>
        </div>
        <p className="description-f">{description}</p>
      </div>
    </div>
  );
}

export default Card;
