import React, { useState, useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../../assets/preload.json';
import "./preload.css";

const ImagePreloader = ({ onPreloadComplete }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loaderAnimationLoaded, setLoaderAnimationLoaded] = useState(false);
  const [minLoadTimePassed, setMinLoadTimePassed] = useState(false);
  const [fadeEffect, setFadeEffect] = useState(false);
  const animationContainer = useRef(null);

  useEffect(() => {
    if (loaderAnimationLoaded && minLoadTimePassed) {
      preloadImagesFromFolders(() => {
        setFadeEffect(true); // Begin fade out effect
        setTimeout(() => setIsLoading(false), 1000); // Wait for fade effect to finish
      });
    }
  }, [loaderAnimationLoaded, minLoadTimePassed]);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData
    });

    anim.addEventListener('DOMLoaded', () => {
      setLoaderAnimationLoaded(true);
    });

    // Set minimum display time for loader
    setTimeout(() => {
      setMinLoadTimePassed(true);
    }, 3000);  // 3000 ms = 3 seconds

    return () => {
      anim.destroy();  // Clean up Lottie instance on component unmount
    };
  }, []);

  const preloadImagesFromFolders = (onComplete) => {
    const contexts = [
      require.context('../../assets/BrandsLogo', false, /\.(png|jpe?g|svg)$/),
      require.context('../../assets/experties', false, /\.(png|jpe?g|svg)$/),
      require.context('../../assets', false, /\.(png|jpe?g|svg)$/),
    ];

    let imagePromises = contexts.flatMap(context =>
      context.keys().map(imagePath => {
        const imageUrl = context(imagePath);
        return fetch(imageUrl)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.blob();
          });
      })
    );

    Promise.all(imagePromises).then(() => {
      onComplete();
      console.log("All images loaded");
      if (minLoadTimePassed) { // Only complete if min time has also passed
        onPreloadComplete();
      }
    }).catch(error => {
      console.error('Error loading images:', error);
    });
  };

  return (
    <div>
      {isLoading ? (
        <div className={`parentContainer ${fadeEffect ? 'fadeOut' : ''}`} ref={animationContainer}>
        {/* Lottie animation will load here */}
      </div>
      ) : null}
    </div>
  );
};

export default ImagePreloader;
