import React, { useEffect, useState } from "react";
import "./styles.css";
import BurgerMenu from '../hamburger-menu/hamburger';
import ModalCareerDialog from "../modal/modalCareer.js";

const Header = ({ handleTalkButton }) => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const [hasBlackBackground, setHasBlackBackground] = useState(false);
  const [openCareer, setCareerOpen] = useState(false);

  const handleCareerOpen = () => setCareerOpen(true);
  const handleCareerClose = () => setCareerOpen(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsHeaderHidden(scrollTop > lastScrollTop);
      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    const targetComponents = [
      document.getElementById('YourComponent'),
      document.getElementById('Signup')
    ];
    if (targetComponents.some(el => el === null)) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Set background to black if any of the targets is intersecting
        setHasBlackBackground(targetComponents.some(el => el && entry.isIntersecting));
      },
      { threshold: 0.1 } // Adjust as needed
    );

    targetComponents.forEach(el => {
      if (el) observer.observe(el);
    });

    return () => targetComponents.forEach(el => el && observer.unobserve(el));
  }, []);

  return (
    <header className={`header ${isHeaderHidden ? 'hidden' : ''} ${hasBlackBackground ? 'black-bg' : ''}`}>
      <ModalCareerDialog
        className="modal-style"
        open={openCareer}
        handleClose={handleCareerClose}
      />
      <BurgerMenu handleCareerOpen={handleCareerOpen} handleTalkButton={handleTalkButton}/>
      <div className="navbar-parent-container">
        <a
          href="/"
          aria-current="page"
          className="navbar-container-logo w-nav-brand w--current"
        >
          <img
            src={require("../../assets/logo.webp")}
            loading="lazy"
            width="114"
            alt="Logo"
            className="navbar-logo-image"
          />
        </a>
        <nav
          role="navigation"
          className="navbar-container-all-items w-nav-menu"
        >
          <div
            className="navbar-container-main-items"
            style={{
              height: 45,
              alignItems: "center",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(10px)",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              borderColor: "rgba(255,255,255,0.2)",
              borderRadius: 10,
              "--white": "#ffffff",
            }}
          >
            <a href="#client-section" className="nav-links w-nav-link">Clients</a>
            
            <a href="#videos" className="nav-links w-nav-link">Work</a>
            <a href="https://wavelaps.com/xrcreatorhackathon/" className="nav-links w-nav-link" id="xrcreatorhack">
              XR Creator Hackathon<i>!</i>
            </a>
            <a href="#expertise-container" className="nav-links w-nav-link">Expertise</a>
            <a onClick={handleCareerOpen} className="nav-links w-nav-link" style={{ cursor: "pointer" }}>Careers</a>
          </div>
          <div className="navbar-container-button">
            <div className="button-main header-button" id="button-6">
              <div id="spin"></div>
              <a id="head-cta-get-in-touch" onClick={handleTalkButton}>Let's talk!</a>
            </div>
          </div>
        </nav>
        <aside id="menu-button-nav" className="menu-button-nav w-nav-button">
          <div id="menu-button-nav-icon" className="w-icon-nav-menu"></div>
        </aside>
      </div>
    </header>
  );
};

export default Header;
