// BurgerMenu.js
import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import './hamburger-style.css';
import { Grid, IconButton } from "@mui/material";
import { WhatsApp, Twitter, Instagram, LinkedIn } from "@mui/icons-material";

const BurgerMenu = ({handleCareerOpen, handleTalkButton}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const showMenu = window.innerWidth <= 480; // Adjust the width to 480px

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 480) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    showMenu && (
      <Menu isOpen={isOpen} onStateChange={(state) => handleStateChange(state)} right >
        <a className="menu-item" href="#client-section" onClick={closeMenu} >Clients</a>
       
        <a className="menu-item" href="#videos" onClick={closeMenu}>Work</a>
        <a href="https://wavelaps.com/xrcreatorhackathon/" className="menu-item" id="xrcreatorhack">
              XR Creator Hackathon<i>!</i>
            </a>
        <a className="menu-item" href="#expertise-container" onClick={closeMenu}>Expertise</a>
        <a className="menu-item" onClick={handleCareerOpen}>Careers</a>
        <a className="menu-item border" onClick={handleTalkButton}>LET'S TALK!</a>
        <div className="social-container">
        <Grid item>
            <IconButton style={{ color: "#000" }} href="https://api.whatsapp.com/send?phone=9354031490&text=Hi! I would like to know more information about Wavelaps." target="_blank">
              <WhatsApp fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton style={{ color: "#000" }} href="https://www.instagram.com/wavelaps_technologies?igsh=eWptcWN6YXR3OXpv">
              <Instagram fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton style={{ color: "#000" }} href="https://www.linkedin.com/company/wavelaps/?viewAsMember=true">
              <LinkedIn fontSize="large" />
            </IconButton>
          </Grid>
        </div>
        

      </Menu>
    )
  );
};

export default BurgerMenu;
