import { width } from "@mui/system";
import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "./testimonials.css"

const testimonials = [
  {
    name: "EMILIANO AQUILANI",
    position: "Admin",
    image:
      "http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg",
    text: "Dramatically maintain clicks-and-mortar solutions without functional solutions.",
  },
  {
    name: "ANNA ITURBE",
    position: "Admin",
    image:
      "http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg",
    text: "Completely synergize resource taxing relationships via premier niche markets.",
  },
  {
    name: "EMILIANO AQUILANI",
    position: "Admin",
    image:
      "http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg",
    text: "Dramatically maintain clicks-and-mortar solutions without functional solutions.",
  },
  {
    name: "ANNA ITURBE",
    position: "Admin",
    image:
      "http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg",
    text: "Completely synergize resource taxing relationships via premier niche markets.",
  },
  {
    name: "EMILIANO AQUILANI",
    position: "Admin",
    image:
      "http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg",
    text: "Dramatically maintain clicks-and-mortar solutions without functional solutions.",
  },
  {
    name: "ANNA ITURBE",
    position: "Admin",
    image:
      "http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg",
    text: "Completely synergize resource taxing relationships via premier niche markets.",
  },
];

const TestimonialCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 850, // Screen width of 480px or below
        settings: {
          slidesToShow: 1, // Change slidesToShow to 1
        },
      },
      {
        breakpoint: 480, // Screen width of 480px or below
        settings: {
          slidesToShow: 1, // Change slidesToShow to 1
        },
      },
      
    ],

  };

  return (
    <TestimonialsSection>
      <TextContainer>
        <h1 className="h2 expertise-text white-text">
          {/* <span className="small-expertise">Our</span> <br />{" "} */}
          <span className="big-expertise disable-select">
            Only we didn’t <span className="brand-color">say</span> this!
          </span>
        </h1>
        <div className="line-expertise"></div>
      </TextContainer>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <TestimonialItem key={index}>
            <ShadowEffect>
              <TestimonialInfoContainer>
                <TestimonialImage
                  src={testimonial.image}
                  alt={testimonial.name}
                />
                <TestimonialNameContainer>
                  <TestimonialName>
                    <b>{testimonial.name}</b>
                  </TestimonialName>
                  <TestimonialPosition>
                    {testimonial.position}
                  </TestimonialPosition>
                </TestimonialNameContainer>
              </TestimonialInfoContainer>
              <hr className="divider" />
              <TestimonialText>{testimonial.text}</TestimonialText>
            </ShadowEffect>
          </TestimonialItem>
        ))}
      </Slider>
    </TestimonialsSection>
  );
};

export default TestimonialCarousel;

const TextContainer = styled.div`
  text-align: center;
  margin: 0 auto 2.187em auto;
  width: 90%;
  .h2 {
    font-size: 45px;
    color: #212121;
  }

  .small-expertise {
    font-size: 0.5em;
  }

  .big-expertise {
    font-weight: 700;
    font-size: 1.5em;
  }
  @media only screen and (max-width: 480px) {
    .big-expertise {
      font-size: 10vw;
    }
  }
`;

const TestimonialsSection = styled.div`
  .slick-slide {
    display: flex;
    justify-content: center;
    transition: transform 0.3s, filter 0.3s;
  }

  .slick-slide:not(.slick-center) {
    filter: blur(5px);
    transform: scale(
      0.8
    ); /* Adjust the scale value to make the non-active items smaller */
  }
`;

const TestimonialItem = styled.div`
  width: 60vw; /* Adjusted width to 60vw */
  color: #313131;
`;

const ShadowEffect = styled.div`
  background: #f4f3ef;
  padding: 20px;
  margin: 33px 20px;
  border-radius: 10px;
  text-align: left;
  border: 1px solid #ececec;
  box-shadow: 11px 10px 29px -12px rgba(33, 33, 33, 0.63);
`;

const TestimonialInfoContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const TestimonialImage = styled.img`
  width: 80px;
  border-radius: 10px;
`;

const TestimonialNameContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const TestimonialName = styled.span`
  font-size: medium;
  @media only screen and (max-width:1024px){
    font-size: 3vw;
  }
  @media only screen and (max-width:850px){
    font-size: 3vw;
  }
  @media only screen and (max-width:480px){
    font-size: 3vw;
  }
  
  
`;

const TestimonialPosition = styled.span`
  font-size: medium;
  
  
  @media only screen and (max-width:1024px){
    font-size: 3vw;
  }
  @media only screen and (max-width:850px){
    font-size: 3vw;
  }
  @media only screen and (max-width:480px){
    font-size: 3vw;
  }
`;

const TestimonialText = styled.p`
  border-left: 3px solid #fcbe0e;
  padding-left: 5px;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 0 17px 0;
  font-weight: 300;
  
  @media only screen and (max-width:1024px){
    font-size: 3vw;
    margin: 0 0 0.5vw 0;
  }
  @media only screen and (max-width:850px){
    
    font-size: 3vw;
    margin: 0 0 0.5vw 0;
  }
  @media only screen and (max-width:480px){
    font-size: 1.8vw;
    margin: 0 0 0.5vw 0;
  }
`;
