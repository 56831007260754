import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "./videoplayer.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useInView } from 'react-intersection-observer';

const videos = [
  {
    id: 1,
    title: "Video 1",
    url: require("../../assets/videos/video1.webm"),
    thumbnail: require("../../assets/videosThumbnail/cover1-removebg-preview.png"),
  },
  {
    id: 2,
    title: "Video 2",
    url: require("../../assets/videos/karaken2.webm"),
    thumbnail: require("../../assets/videosThumbnail/cover2-removebg-preview.png"),
  },
  {
    id: 3,
    title: "Video 3",
    url: require("../../assets/videos/video3.webm"),
    thumbnail: require("../../assets/videosThumbnail/cover3-removebg-preview.png"),
  },
  {
    id: 4,
    title: "Video 4",
    url: require("../../assets/videos/video4.webm"),
    thumbnail: require("../../assets/videosThumbnail/cover4-removebg-preview.png"),
  },
  {
    id: 5,
    title: "Video 5",
    url: require("../../assets/videos/video5.webm"),
    thumbnail: require("../../assets/videosThumbnail/cover5-removebg-preview.png"),
  },
  {
    id:6,
    title: "Video 6",
    url: require("../../assets/videos/Harry potter.mp4"),
    thumbnail: require("../../assets/videosThumbnail/Harry Porter-01.png"),
  },
  {
    id:7,
    title:"video 7",
    url:require("../../assets/videos/Claro user flow.mp4"),
    thumbnail:require("../../assets/videosThumbnail/claro.png"),
  },
  {
    id:8,
    title:"video 8",
    url:require("../../assets/videos/Ck user flow video.mp4"),
    thumbnail:require("../../assets/videosThumbnail/CK (1).png")
  }
];

const VideoCarousel = () => {
  const [currentVideo, setCurrentVideo] = useState(videos[0]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [userInteracted, setUserInteracted] = useState(false);
  const videoRefs = useRef([]);
  const sliderRef = useRef(null);
  const thumbnailSliderRef = useRef(null);
  const thumbnailContainerRef = useRef(null);
  var videoRefIndex = 0;

  const { ref, inView } = useInView({
    triggerOnce: false, 
    threshold: 0.1,    
  });

  const handleThumbnailClick = (video, index) => {
    setCurrentVideo(video);
    sliderRef.current.slickGoTo(index);
    thumbnailSliderRef.current.slickGoTo(index);
  };

  useEffect(() => {
    if (inView) {
      if(window.innerWidth < 480){
        if(videoRefs.current[currentVideoIndex+2].paused){
          videoRefs.current[currentVideoIndex+2].muted = !userInteracted;
          videoRefs.current[currentVideoIndex+2].play();
          videoRefs.current[currentVideoIndex+2].classList.add("slick-current");
          thumbnailContainerRef.current?.firstChild.classList.add('thumbnail-active')
        }
      }
      else{
        if(videoRefs.current[currentVideoIndex+4].paused){
          videoRefs.current[currentVideoIndex+4].muted = !userInteracted;
          videoRefs.current[currentVideoIndex+4].play();
          videoRefs.current[currentVideoIndex+4].classList.add("slick-current");
          thumbnailContainerRef.current?.firstChild.classList.add('thumbnail-active')
        }
      }
    }
    else{
      console.log("pausing video");
      if(window.innerWidth < 480){
        if(!videoRefs.current[currentVideoIndex+2].paused){
          videoRefs.current[currentVideoIndex+2].pause();
        }
      }
      else{
        console.log("pausing  video 1");
        if(!videoRefs.current[currentVideoIndex+4].paused){
          console.log("pausing video 2");
          videoRefs.current[currentVideoIndex+4].pause();
        }
      }
    }
  }, [inView]);

  const StoreVideoRef = (el) => {
    videoRefs.current[videoRefIndex] = el;
    videoRefIndex++;
  }

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    focusOnSelect: true,
    asNavFor: thumbnailSliderRef.current,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
        },
      }
    ],
    beforeChange: (oldIndex, newIndex) => {
      setCurrentVideoIndex(newIndex);
      if(!userInteracted){
        setUserInteracted(true);
      }
      if(window.innerWidth < 480){
        videoRefs.current[oldIndex+2].classList.remove("slick-current");
        videoRefs.current[newIndex+2].classList.add("slick-current");
        videoRefs.current[oldIndex+2].currentTime = 0;
        videoRefs.current[oldIndex+2].pause();
        thumbnailContainerRef.current?.children[oldIndex].classList.remove('thumbnail-active')
        if(videoRefs.current[newIndex+2].paused){
          videoRefs.current[newIndex+2].muted = false;
          videoRefs.current[newIndex+2].currentTime = 0;
          videoRefs.current[newIndex+2].play();
          thumbnailContainerRef.current?.children[newIndex].classList.add('thumbnail-active')
        }
      }
      else{
        videoRefs.current[oldIndex+4].classList.remove("slick-current");
        videoRefs.current[newIndex+4].classList.add("slick-current");
        videoRefs.current[oldIndex+4].currentTime = 0;
        videoRefs.current[oldIndex+4].pause();
        thumbnailContainerRef.current?.children[oldIndex].classList.remove('thumbnail-active')
        if(videoRefs.current[newIndex+4].paused){
          videoRefs.current[newIndex+4].muted = false;
          videoRefs.current[newIndex+4].currentTime = 0;
          videoRefs.current[newIndex+4].play();
          thumbnailContainerRef.current?.children[newIndex].classList.add('thumbnail-active')
        }
      }
      thumbnailSliderRef.current.slickGoTo(newIndex);
    },
  };

  const CustomArrow = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fcbe0e;
    font-size: 20px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  `;

  const PrevArrow = styled(CustomArrow)`
    left: -40px;
  `;

  const NextArrow = styled(CustomArrow)`
    right: -40px;
  `;

  const PrevArrowComponent = (props) => {
    const { onClick } = props;
    return <PrevArrow onClick={onClick}>&lt;</PrevArrow>;
  };

  const NextArrowComponent = (props) => {
    const { onClick } = props;
    return <NextArrow onClick={onClick}>&gt;</NextArrow>;
  };

  const thumbnailSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    asNavFor: sliderRef.current,
    prevArrow: <PrevArrowComponent />,
    nextArrow: <NextArrowComponent />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      }
    ],
  };

  return (
    <CarouselContainer ref={ref}>
      <TextContainer>
        <h1 className="h2 expertise-text white-text">
          <span className="big-expertise disable-select">
            Are we <span className="brand-color">worth</span> your trust?
          </span>
        </h1>
        <div className="line-expertise"></div>
      </TextContainer>
      <SliderContainer>
        <Slider ref={sliderRef} {...settings}>
          {videos.map((video, index) => (
            <VideoWrapper key={video.id}>
              <StyledVideo
                ref={(el) => StoreVideoRef(el)}
                src={video.url}
                playsInline
                loop
                controls
              />
            </VideoWrapper>
          ))}
        </Slider>
      </SliderContainer>
      <ThumbnailSliderContainer>
        <Slider ref={thumbnailSliderRef} {...thumbnailSliderSettings}>
          {videos.map((video, index) => (
            <Thumbnail key={video.id} onClick={() => handleThumbnailClick(video, index)}>
              <ThumbnailImage src={video.thumbnail} alt={video.title} />
            </Thumbnail>
          ))}
        </Slider>
      </ThumbnailSliderContainer>
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`
  width: 100%;
`;

const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 2.187em;
  margin: 0 auto 2.187em auto;
  width: 90vw;
  .h2 {
    font-size: 45px;
    color: #f4f3ef;
  }

  .small-expertise {
    font-size: 0.5em;
  }

  .big-expertise {
    font-weight: 700;
    font-size: 1.5em;
  }
  @media only screen and (max-width: 480px) {
    .big-expertise {
      font-size: 10vw;
    }
  }
`;

const SliderContainer = styled.div`
  height:550px;
 
  @media only screen and (max-width: 1024px) {
    height: 65vw;
    width: 100vw;
  }
  @media only screen and (max-width: 850px) {
    height: 100vw;
  }
  @media only screen and (max-width: 480px) {
    height: 100vw;
  }
`;

const ThumbnailSliderContainer = styled.div`
  position: relative;
  width: 15vw;
  margin: 14px auto 0;

  @media only screen and (max-width: 1024px) {
    width: 40vw;
  }

  @media only screen and (max-width: 768px) {
    width: 50vw;
  }

  @media only screen and (max-width: 480px) {
    width: 60vw;
  }

  .slick-slide {
    padding: 0 5px;
    box-sizing: border-box;
  }

  .slick-current {
    .thumbnail-image {
      box-shadow: 0px 0px 15px -3px #fcbe0e;
    }

  }
    .slick-list {
    overflow: hidden; // Hide non-visible slides
  }
     .slick-slide:not(.slick-active) {
    visibility: hidden;
  }
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95%;
`;

const StyledVideo = styled.video`
  border-radius: 15px;
  height: 450px;
  filter: blur(5px);
  transition: all 0.3s ease;
  &.slick-current {
    box-shadow: 0px 0px 15px -3px #fcbe0e;
    height: 550px;
    filter: none;
   
  }

  @media only screen and (max-width:1024px){
    height:47vw;
    &.slick-current {
      height: 49vw;
    }
  }
    
  @media only screen and (max-width:850px){
    height:90vw;
    &.slick-current {
      height: 100vw;
    }
  }

  @media only screen and (max-width:480px){
    height:90vw;
    &.slick-current {
      height: 100vw;
    }
  }
`;

const Thumbnail = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  box-sizing: border-box;
`;

const ThumbnailImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0px 0px 1px -3px white;
  transition: all 0s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export default VideoCarousel;