import React, { useEffect, useRef } from 'react';
import './popup.css';
import { X } from 'lucide-react';

const PopUp = ({ closePopup }) => {
  const popupRef = useRef(null);

  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);
    
    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closePopup]);

  return (
    <div className="popup">
      <div ref={popupRef} className="popup-content">
        <button onClick={closePopup} className="popup-close">
          <X size={24} />
        </button>
        <div className="popup-text">
          <a href="https://wavesindia.org/" className="popup-link">
            TO KNOW MORE ABOUT <b>WAVES Summit</b> CLICK HERE
          </a>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
